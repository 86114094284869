<template>
  <TextBlock
    :cols="mappedCols"
    :cols-centered="item.fields.textCenteredInRow"
    :content-centered="item.fields.textCentered"
    :lead="item.fields.textLeadStyle"
  >
    <LibRichTextRenderer
      v-if="item.fields.text"
      :rich-text="item.fields.text"
    ></LibRichTextRenderer>
  </TextBlock>
</template>

<script setup lang="ts">
import { TextBlock } from '@hypercodestudio/basler-components';
import type { IParagraph } from '~/lib/ContentfulService';
import { mapColsWidth } from '~/utils/mapColsWidth';

interface Props {
  item: IParagraph;
}

const props = defineProps<Props>();

const mappedCols = computed(() => mapColsWidth(props.item.fields.textWidth));
</script>
